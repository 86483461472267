<template>
  <NationalSystem title="WCP (Mobile)" :filter="r => r.notebook === 'WCP-Mobile'" :scan-results="scanResults">
    WCP's Mobile extract contains all daily national activity.
    This file was not received (so far) on this day.
  </NationalSystem>
</template>

<script>
export default {
  props: {
    scanResults: Array
  }
}
</script>