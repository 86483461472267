<template>
  <NationalSystem title="GP (MPL Demographics)" :filter="r => r.notebook === 'Demo-GP'" :scan-results="scanResults">
    A single GP demographic file is pushed to NIIAS for the whole of Wales each day.
    This file was not received (so far) on this day.
  </NationalSystem>
</template>

<script>
export default {
  props: {
    scanResults: Array
  }
}
</script>