<template>
  <section class="report">
    <div class="columns">
      <div class="column" v-if="report.day_stats">
        <day-stats class="mt-5" :day-stats="report.day_stats"/>
      </div>
      <div class="column">
        <b-tabs class="block mt-5">
          <b-tab-item label="Missing Extracts">
            <missing-extracts :scan-results="report.scan_results"/>
          </b-tab-item>
          <b-tab-item label="All Extracts">
            <scan-results :scan-results="report.scan_results"/>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
    <hr/>
    <p>Data last fetched at: {{ report.run_at.split('T')[1].split('.')[0] }}</p>
  </section>
</template>

<script>
import ScanResults from './ScanResults'
import DayStats from './DayStats'
import MissingExtracts from './MissingExtracts.vue'

export default {
  components: { ScanResults, DayStats, MissingExtracts },
  props: {
    report: Object
  }
}
</script>