import axios from 'axios'
// import router from '../router'
import store from '../store'
import moment from 'moment'

const API_VERSION = 1
const e = encodeURIComponent

// NOTE: you can decide where to host the API from here:
const env = process.env.NODE_ENV || 'development'
let BASE_URL = ''
if (env === 'development') {
  BASE_URL = 'http://127.0.0.1:9080'
}

class Api {
  /**
   * Create an instance of the API
   * @param {String} baseURL the base domain (no slash at end)  for the API calls to be directed to
   * @param {Number} version the API version
   */
  constructor (baseURL, version) {
    this.baseURL = baseURL
    this.version = `v${version}`
    this.ajax = axios.create({
      baseURL: `${BASE_URL}/api/${this.version}`,
      timeout: 20 * 1000,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
  }

  /**
     * When an error is thrown by the ajax call, if it's a 401, we need to re-authenticate here.
     * @param {Error} e the error caught.
     * @param {Boolean} dontRedirect401s if detected a 401 do not redirect to the error page
     */
  errorFilter (e, dontRedirect401s) {
    if (e.response && e.response.status === 401 & !dontRedirect401s) {
      document.location.href = `${this.baseURL}/oauth/login` // redirect to log-in page
      return Promise.reject(e)
    }
    if (e.response && e.response.data) {
      return Promise.reject(new Error(e.response.data.Detail))
    }
    return Promise.reject(e)
  }

  /**
   * scan the response from the server, if there's an updated token, use this instead
   * of the one we're using now.
   * @param {*} r the axios response object
   */
   tokenScanner (r) {
    const newToken = r.headers['x-updated-token']
    if (newToken) {
      // console.log('we have a new token!', newToken);
      store.commit('updateToken', newToken)
    }
    return r
  }

  /**
   * @returns {String} a nonce for preventing caching
   */
  nonce () {
    return new Date().toTimeString()
  }

  token () {
    return store.state.token
  }

  async runReport (date) {
    const dayISOFormat = moment(date).format('YYYY-MM-DD')
    return this.ajax.post(`/days/${e(dayISOFormat)}/report?nonce=${this.nonce()}`, {}, { headers: { Authorization: `Bearer ${this.token()}` } })
      .then(r => this.tokenScanner(r).data)
      .catch(e => this.errorFilter(e))
  }
}

export default new Api(BASE_URL, API_VERSION)