<template>
  <div class="home">
    <h1 class="title has-text-primary">Health Console</h1>
    <h2 class="subtitle">Select a day to see which extracts were received</h2>
    <date-picker v-model="daySelected"/>

    <report v-if="report" :report="report"/>
    <div v-else class="notification">Loading&hellip;</div>
  </div>
</template>

<script>
import Api from '../api/api'
import DatePicker from '../components/DatePicker'
import Report from '../components/Report'

export default {
  components: { DatePicker, Report },
  data () {
    const today = new Date()
    // oneDayAgo.setDate(oneDayAgo.getDate() - 1)
    return {
      daySelected: today,
      report: null,
    }
  },
  watch: {
    async daySelected () {
      await this.updateReport()
    }
  },
  async mounted () {
    await this.updateReport()
  },
  methods: {
    async updateReport () {
      this.report = null
      console.log('updateReport', this.daySelected)
      this.report = await Api.runReport(this.daySelected)
    }
  }
}
</script>
