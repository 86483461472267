<template>
  <div class="scan_results">
    <scan-result v-for="(sr, i) in scanResults" :key="sr.received_at" 
      :scan-result="sr" :previous="i === 0 ? null : scanResults[i-1]"/>
  </div>
</template>

<script>
import ScanResult from './ScanResult'

export default {
  components: { ScanResult },
  props: {
    scanResults: Array
  }
}
</script>