<template>
  <div class="scanResult">
    <div v-if="needsGroupingHeader" class="result-header has-top-margin">
      <h2 class="subtitle">
        {{ cleanNotebook }}
      </h2>
    </div>
    <code @click="showResult = true" :class="`${klass} is-clickable`">{{ fileName }}</code>
    <b-modal
      v-model="showResult"
      :destroy-on-hide="false">
      <p class="has-text-light">Received this extract at: {{ received_at }}</p>
      <pre>{{ cleanResult }}</pre>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    previous: Object,
    scanResult: Object
  },
  data () {
    return {
      showResult: false
    }
  },
  computed: {
    cleanNotebook () {
      return this.scanResult.notebook.replace('Demo-', 'Demographics: ')
    },
    received_at () {
      return new Date(Date.parse(this.scanResult.received_at)).toLocaleString()
    },
    fileName () {
      const split = this.scanResult.path.split('\\')
      return split[split.length - 1]
    },
    needsGroupingHeader () {
      if (!this.previous) return true
      return this.previous.notebook !== this.scanResult.notebook
    },
    hasError () {
      return this.scanResult.result.indexOf('Traceback') !== -1
    },
    hasWarning () {
      return this.scanResult.result.indexOf('used 0 ') !== -1
    },
    klass () {
      if (this.hasError) return 'has-text-danger'
      if (this.hasWarning) return 'has-text-warning'
      return 'has-text-success'
    },
    cleanResult () {
      return this.scanResult.result
        .replace(/\[0m/g, '')
        .replace(/\[[0-9];[0-9]{1,3}(m)?/g, '')
    }
  }
}
</script>