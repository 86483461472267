<template>
  <NationalSystem title="WDS" :filter="r => r.notebook === 'WDS'" :scan-results="scanResults">
    A single WDS extract contains all daily national activity.
    This file was not received (so far) on this day.
  </NationalSystem>
</template>

<script>
export default {
  props: {
    scanResults: Array
  }
}
</script>