<template>
  <NationalSystem title="CYPRIS" :filter="r => r.notebook === 'CYPRIS'" :scan-results="scanResults">
    CYPRIS sends a single extract for the whole of Wales each day.
    This file was not received (so far) on this day.
  </NationalSystem>
</template>

<script>
export default {
  props: {
    scanResults: Array
  }
}
</script>