<template>
  <LocalSystems 
    title="WPAS (formally Myrddin)"
    notebook="WPAS"
    :scanResults="scanResults"
    :expectedPrefixes="expectedPrefixes">
    <p>WPAS sends one extract per Healthboard, the following are <strong>missing</strong> on this day:</p>
  </LocalSystems>
</template>

<script>
import LocalSystems from './LocalSystems.vue'

const Powys = 'MYRDDINPOWYS'
const SwanseaBay = 'MYRDDINAB'
const CwmTaf = 'MYRDDINCWMTAF'
const Betsi = 'MYRDDINBCU'
const Abmu = 'MYRDDINABMU'
const HywelDda = 'MYRDDINHDDA'

export default {
  components: { LocalSystems },
  props: {
    scanResults: Array
  },
  data () {
    return {
      expectedPrefixes: {
        'Powys': Powys,
        'Swansea Bay': SwanseaBay,
        // no cardiff and vale
        'Cwm Taf': CwmTaf,
        'Betsi': Betsi,
        'ABMU': Abmu,
        'Hywel Dda': HywelDda,
        // no velindre
      },
    }
  }
}
</script>