import { render, staticRenderFns } from "./AaaBowel.vue?vue&type=template&id=294f801d&"
import script from "./AaaBowel.vue?vue&type=script&lang=js&"
export * from "./AaaBowel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports