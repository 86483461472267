<template>
  <NationalSystem title="AAA/Bowel" :filter="r => r.notebook === 'AAA-Bowel'" :scan-results="scanResults">
    AAA and Bowel and pulled by NIIAS in a combined SQL job, 
    so one of the scheduled tasks within NIIAS may have failed 
    as we received no trigger (so far) to pull this data on this day.
  </NationalSystem>
</template>

<script>
export default {
  props: {
    scanResults: Array
  }
}
</script>