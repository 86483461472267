<template>
  <div v-if="any_missing" class="block national-system">
    <h3 class="subtitle is-5 has-text-danger">
      <font-awesome-icon icon="exclamation-triangle"/>
      {{ title }}
    </h3>
    <div>
      <slot />
      <div v-for="key in Object.keys(expectedPrefixes)" :key="key">
        <div v-if="!has_some(expectedPrefixes[key])">
          • {{ key }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    scanResults: Array,
    expectedPrefixes: Object,
    notebook: String,
    title: String,
  },
  computed: {
    all_extracts () { return this.scanResults.filter(r => r.notebook === this.notebook) },
    missing () { 
      return Object.keys(this.expectedPrefixes)
        .filter(k => !this.has_some(this.expectedPrefixes[k]))
        .reduce((a, c) => { a[c] = this.expectedPrefixes[c]; return a }, {})
    },
    any_missing () {
      return Object.keys(this.missing).length > 0
    }
  },
  methods: {
    has_some (prefix) {
      return this.all_extracts.filter(r => r.path.indexOf(prefix) !== -1).length > 0
    }
  }
}
</script>