<template>
  <div v-if="count === 0" class="block esr">
    <h3 class="subtitle is-5 has-text-warning">
      <font-awesome-icon icon="exclamation-circle"/>
      ESR
    </h3>
    <p>
      NIIAS pulls ESR 'delta' files from an SFTP location. 
      Some days, there isn't any changes to the data in ESR and therefore no files
      will be received, but this is quite rare. If you find this is a common occurrence
      it would be worth checking the ESR pull logs to look for re-occurring issues.</p>
  </div>
</template>

<script>
export default {
  props: {
    scanResults: Array
  },
  computed: {
    count () {
      return this.scanResults.filter(r => r.notebook === 'Demo-ESR').length
    }
  }
}
</script>