<template>
  <LocalSystems 
    title="WCP"
    notebook="WCP"
    :scanResults="scanResults"
    :expectedPrefixes="expectedPrefixes">
    <p>
      WCP sends one extract per Healthboard, one for WAST and several for All Wales and OOH. 
      The following are <strong>missing</strong> on this day:
    </p>
  </LocalSystems>
</template>

<script>
import LocalSystems from './LocalSystems.vue'

const Abmu = 'ABMTest_'
const Betsi = 'BCUTest_'
const CwmTaf = 'CTTTest_'
const Cav = 'CAVTest_'
const GPOOH = 'GPOOH_'
const SwanseaBay = 'ABBTest_'
const HywelDda = 'HDDTest_'
const Velindre = 'VEL_'
const VelindreOOH = 'VELOOH_'
const WelshAmbulance = 'WAST_'

export default {
  components: { LocalSystems },
  props: {
    scanResults: Array
  },
  data () {
    return {
      expectedPrefixes: {
        'ABMU': Abmu,
        'Betsi': Betsi,
        'Cwm Taf': CwmTaf,
        'Cardiff & Vale': Cav,
        'GP Out of Hours': GPOOH,
        'Swansea Bay': SwanseaBay,
        'Hywel Dda': HywelDda,
        'Velindre': Velindre,
        'Velindre (Out of Hours)': VelindreOOH,
        'Welsh Ambulance Service (WAST)': WelshAmbulance,
      },
    }
  }
}
</script>