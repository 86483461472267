<template>
  <NationalSystem title="WIS" :filter="r => r.notebook === 'WIS'" :scan-results="scanResults">
    A single WIS extract contains all daily national activity.
    This file was not received (so far) on this day.
  </NationalSystem>
</template>

<script>
export default {
  props: {
    scanResults: Array
  }
}
</script>