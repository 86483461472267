<template>
  <div class="card p-4 is-light">
  <b-field label="Select a day">
      <b-datepicker
          :value="value"
          @input="e => $emit('input', e)"
          :show-week-number="false"
          locale="en-GB"
          icon-pack="fas"
          icon="calendar"
          placeholder="Click to select..."
          trap-focus>
      </b-datepicker>
  </b-field>
  </div>
</template>

<script>
export default {
  emits: ['input'],
  props: {
    value: Date,
  }
}
</script>