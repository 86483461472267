<template>
  <NationalSystem title="Choose Pharmacy (Audit Log)" :filter="r => r.notebook === 'ChoosePharmacy'" :scan-results="scanResults">
    Choose Pharmacy sends a single extract for the whole of Wales each day.
    This file was not received (so far) on this day.
  </NationalSystem>
</template>

<script>
export default {
  props: {
    scanResults: Array
  }
}
</script>