<template>
  <div class="daystats">
    <!-- <div class="none" v-if="!dayStats">No day stats have been generated for this day yet.</div> -->
    <div class="block">
      <div v-if="hidden" class="block mt-6">
        <h2 class="title is-6">Hidden Report</h2>
        <h3 class="subtitle is-6">All Organisations hidden from NIIAS</h3>
        <ul class="notification is-light">
          <li v-for="org in hidden" :key="org.hb">
            <strong>{{ org.hb }}:</strong> {{ org.occurrences.toLocaleString() }} occurrences
          </li>
        </ul>
      </div>

      <h2 class="title is-6 mt-6">Totals</h2>
      <pre>
Alerts generated: {{ dayStats.alert_count.toLocaleString() }}
Patient Accesses recorded: {{ dayStats.record_count.toLocaleString() }}
Alert Ratio: {{ ratio }}%</pre>
  </div>
    <div class="block">
      <h2 class="title is-6">Alerts by Trust</h2>
      <pre>{{ dayStats.breakdown_by_trust }}</pre>
    </div>
    <div class="block">
      <h2 class="title is-6">Alerts by Clinical App</h2>
      <pre>{{ dayStats.breakdown_by_clinical_app }}</pre>
    </div>
    <div class="block">
      <h2 class="title is-6">Alerts by Policy</h2>
      <pre>{{ dayStats.breakdown_by_policy }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dayStats: Object
  },
  computed: {
    ratio () {
      return Math.ceil(((this.dayStats.alert_count+0.0) / (this.dayStats.record_count+0.0)) * 100)
    },
    hidden () { 
      const json = (this.dayStats||{}).hidden_report
      if ((json || '') !== '') {
        const parsed = JSON.parse(json)
        return Object.keys(parsed).map(hb => ({ hb, occurrences: parsed[hb] }))
      }
      return null
    }
  }
}
</script>