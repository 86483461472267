<template>
  <NationalSystem title="Choose Pharmacy (Demographics)" :filter="r => r.notebook === 'Demo-ChoosePharmacy'" :scan-results="scanResults">
    Choose Pharmacy sends a single demographic file for the whole of Wales each day.
    This file was not received (so far) on this day.
  </NationalSystem>
</template>

<script>
export default {
  props: {
    scanResults: Array
  }
}
</script>