<template>
  <div class="missing block content">
    <p>Any missing extracts for this day will appear in this section.</p>
    <aaa-bowel :scanResults="scanResults"/>
    <choose-pharmacy :scanResults="scanResults"/>
    <demo-choose-pharmacy :scanResults="scanResults"/>
    <cypris :scanResults="scanResults"/>
    <esr :scanResults="scanResults"/>
    <demo-gp :scanResults="scanResults"/>
    <wcp :scanResults="scanResults"/>
    <wcp-mobile :scanResults="scanResults"/>
    <wds :scanResults="scanResults"/>
    <wis :scanResults="scanResults"/>
    <wpas :scanResults="scanResults"/>
  </div>
</template>

<script>
import AaaBowel from './clinicalapps/AaaBowel'
import ChoosePharmacy from './clinicalapps/ChoosePharmacy.vue'
import Cypris from './clinicalapps/Cypris.vue'
import DemoChoosePharmacy from './clinicalapps/DemoChoosePharmacy.vue'
import DemoGp from './clinicalapps/DemoGp.vue'
import Esr from './clinicalapps/Esr.vue'
import Wcp from './clinicalapps/Wcp.vue'
import WcpMobile from './clinicalapps/WcpMobile.vue'
import Wds from './clinicalapps/Wds.vue'
import Wis from './clinicalapps/Wis.vue'
import Wpas from './clinicalapps/Wpas.vue'

export default {
  components: {
    AaaBowel,
    ChoosePharmacy,
    DemoChoosePharmacy,
    Cypris,
    Esr,
    DemoGp,
    Wcp,
    WcpMobile,
    Wds,
    Wis,
    Wpas,
  },
  props: {
    scanResults: Array
  }
}
</script>